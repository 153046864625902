import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';
import { formValueSelector } from 'redux-form/immutable';

import EK from '../keys';
import selectEntities from '../../modules/entities/selectors';
import SpecFittingInstanceSchema from './schema';
import { selectSidebarIsFiltering } from '../../routes/Dashboard/selectors';
import { selectCurrentFittingInstances } from '../FittingInstances/selectors';
import { convertToSpecFittingInstanceType } from '../../routes/Piping/SpecsFittingsEdit/actions';

export const selectNormalizedSpecFittingInstances = () => createSelector(
  selectEntities(),
  entities => entities.get('specFittingInstances')
);

export const selectCurrentNormalizedSpecFittingInstances = (specId) =>
  createSelector(
    selectNormalizedSpecFittingInstances(),
    instances => instances.toList().filter(item => item.spec == specId)
  );

const specFilterForm = formValueSelector(`filters.${EK.SPEC_FITTING_INSTANCES.state}`);

function removeDuplicateFromSpecFittingInstancesArray(arr) {
  // borrowed this from https://www.geeksforgeeks.org/how-to-get-all-unique-values-remove-duplicates-in-a-javascript-array/, it's pretty slick.
  let outputArray = arr.filter(function (v, i, self) {
    if (v.fittingInstance) {
      return v?.fittingInstance.id && i == self.findIndex((item) => item?.fittingInstance?.id ? item.fittingInstance.id == v.fittingInstance.id : item.id == v.fittingInstance.id);
    }
    return v?.id && i == self.findIndex((item) => item?.fittingInstance?.id ? item.fittingInstance.id == v.id : item.id == v.id);
  });

  return outputArray;
}

// gets all of the instances for a fitting family, concatenates that list with the list of specfittinginstances, and then removes the duplicates
export const selectCurrentFilteredSpecFittingInstances = (specId, fittingId) => createSelector(
  selectEntities(),
  selectSidebarIsFiltering(),
  selectCurrentNormalizedSpecFittingInstances(specId),
  selectCurrentFittingInstances(fittingId),
  state => specFilterForm(state, 'sizeProperty', 'schedule', 'stockno', 'mccsCode'),
  (entities, isFiltering, instances, fittingInstances, query) => {
    const specFittingInstances = instances && instances.filter(item => typeof (item.fittingFamilyId) == 'string' ? item.fittingFamilyId == fittingId : item.fittingFamilyId?.id == fittingId);
    const denSPI = specFittingInstances && denormalize(isFiltering ? specFittingInstances.filter(i => i.doesMatchQuery(query)) : specFittingInstances, [SpecFittingInstanceSchema], entities).toArray() || [];
    return removeDuplicateFromSpecFittingInstancesArray(fittingInstances && denSPI.concat(fittingInstances.toArray()) || denSPI).map(item => convertToSpecFittingInstanceType(item));
  }
);
