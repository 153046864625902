import OrientationSchema from './schema';
import { createSelector } from 'reselect';
import { formValueSelector } from 'redux-form/immutable';

import EK from '../keys';

import { selectSidebarIsFiltering } from '../../routes/Dashboard/selectors';

import selectEntities from '../../modules/entities/selectors';

const orientationFilterForm = formValueSelector(
  `filters.${EK.ORIENTATIONS.state}`
);

export const selectNormalizedOrientations = () =>
  createSelector(
    selectEntities(),
    (entities) => entities.get(EK.ORIENTATIONS.state)
  );

export const selectCurrentOrientationsCount = () =>
  createSelector(
    selectNormalizedOrientations(),
    (ors) => ors && ors.size || 0 
  );

export const selectCurrentFilteredOrientations = () =>
  createSelector(
    selectSidebarIsFiltering(),
    selectNormalizedOrientations(),
    (state) => orientationFilterForm(state, 'name', 'description'),
    (isFiltering, orientations, query) =>
      isFiltering
        ? orientations.filter((o) => o.doesMatchQuery(query))
        : orientations
  );


export const selectOrientations = () =>
  createSelector(selectEntities(), (entities) =>
    denormalize(
      entities.get(EK.ORIENTATIONS.state),
      [OrientationSchema],
      entities
    )
  );