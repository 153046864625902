import React, { useEffect, useState, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { withRouter } from 'react-router-dom';

// Grid Imports
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import '../../../components/primeGrid/style.css';

// Hooks
import { useOnCellEditComplete } from '../../../hooks/useOnCellEditComplete';

// cell bodies/editors
import EntityRowTools from '../../../components/common/EntityTools/RowStartTools';
import Text from '../../../components/common/Text';
import { InputText } from 'primereact/inputtext';
import colors from '../../../assets/themes/base/colors';
import { MultiSelect } from 'primereact/multiselect';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import MultiSelectCellRenderer from '../../../components/grid/MultiSelectCellRenderer';
import { getUnitLength } from '../../../entities/UnitSystems/model';
import UnitValueCellRenderer from '../../../components/grid/UnitValueCellRenderer';

// Header
import Flex from '../../../components/common/Flex';
import {
  PipingModulePipesBendsPath,
  PipingModulePipesBendMachinesPath,
  PipingModulePipesBendSpringbacksPath,
  PipingModulePipesMitersPath,
  PipingModulePipesEditPath,
} from '../../../paths';
import Header from '../../templates/Structures/Header';
import Options from '../../templates/Structures/Options';
import { ConstructedOptions } from './components';
import Skeleton from 'react-loading-skeleton';
import { pluralize } from '../../../components/common/Header';
import { PipeDialog } from '../../../containers/dialog/templates/PipeDialog';
import { push } from 'connected-react-router';

// dropdown constants and validation
import {
  BlankPipe,
  PIPE_SHAPES,
  VALIDATION_FIELDS,
} from '../../../entities/PipeFamilies/model';
import { rowStyles } from '../../../components/primeGrid/RowStyles';
import { convertToPipeType } from '../Pipes/Body';
import { areFieldsValid, doRequiredFieldsExist } from '../Pipes/components';

// Actions
import { processSavePipeInstances } from '../../../entities/PipeInstances/actions';
import { convertToPipeInstanceType, getEndTypesArray, saveCurrentPipeInstanceChanges } from './actions';
import { processEditPipeFamily, processFetchIndividualPipeFamily } from '../../../entities/PipeFamilies/actions';

// selectors
import {
  selectPipingModulePermissionsAndState,
  selectShowHiddenGridColumns,
} from '../../Dashboard/selectors';
import {
  selectCurrentFilteredPipeInstances,
  selectPipeInstanceCount
} from '../../../entities/PipeInstances/selectors';
import {
  selectCurrentPipe,
  selectCurrentPipeUnitSystem,
} from '../../PipesEditSwitch/selectors';
import ComplexCellRenderer from '../../../components/grid/ComplexCellRenderer';
import { selectNormalizedSizes } from '../../../entities/Sizes/selectors';
import { selectNormalizedSchedules } from '../../../entities/Schedules/selectors';
import { selectNormalizedEndTypes } from '../../../entities/EndTypes/selectors';

const mapStateToProps = (PipeId) => createSelector(
  selectPipingModulePermissionsAndState(),
  selectCurrentPipe(PipeId),
  selectCurrentFilteredPipeInstances(PipeId),
  selectCurrentPipeUnitSystem(PipeId),
  selectPipeInstanceCount(PipeId),
  selectNormalizedSizes(),
  selectNormalizedSchedules(),
  selectNormalizedEndTypes(),
  (
    {
      isLoadingInitialData,
      isFetching,
      canCollaborate,
      hasValidLicense,
      ...rest
    },
    pipe,
    data,
    defaultUnitSystem,
    instanceCount,
    sizes,
    schedules,
    endTypes,
  ) => {
    const isLoading = isLoadingInitialData ||
      (isFetching && (!data || instanceCount == 0));
    return {
      ...rest,
      data: (!isLoading && data) || [],
      editable: canCollaborate && hasValidLicense && pipe && !pipe.archivedFlag,
      pipeId: (pipe && pipe.id) || null,
      pipeShape: (pipe && pipe.crossSectionShape) || undefined,
      defaultUnitSystem,
      pipeCounts: pipe && pipe.pipeInstances.size || 0,
      canCollaborate,
      hasValidLicense,
      pipe,
      instanceCount,
      sizes: sizes && sizes.toList().toArray() || [],
      schedules: schedules && schedules.toList().toArray() || [],
      endTypes: endTypes && endTypes.toList().toArray() || [],
    };
  },
);

function PipesEditGrid ({match}) {
  const reduxProps = useSelector(mapStateToProps(match.params.id));
  const dispatch = useDispatch();

  // dialog state
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [validation, setValidation] = useState(VALIDATION_FIELDS);
  const [editedPipeFamily, setEditedPipeFamily] = useState(BlankPipe);

  // table state
  const [editedRows, resetEditedRows, currentEditingRow, isEditing, onBeforeCellEditShow, onCellChange, onCellEditComplete, handleRowAction, onDropdownComplete] = useOnCellEditComplete(reduxProps.data, convertToPipeInstanceType, reduxProps.editable, true);
  const currentUnitSystem = useMemo(() => editedPipeFamily && editedPipeFamily.unitSystem || reduxProps.defaultUnitSystem, [reduxProps.pipe]);
  const columnWidth = useMemo(() => reduxProps.pipeShape == PIPE_SHAPES.rectangular.value && '12%' || '14%', [reduxProps.pipe]);

  //dialog methods
  const handleEditedDialogChange = (newValue, field) => {
    // called on every edit, needs to be made into a custom hook of some kind
    setEditedPipeFamily((old) => {
      let _editedRow = {...old};
      _editedRow[`${field}`] = newValue;
      return _editedRow;
    });
  };

  const savePipeAction = () => {
    // translated here to a format that can be sent to the api, replacing all object fields with their Id.
    setValidation(areFieldsValid(editedPipeFamily));
    if (!doRequiredFieldsExist(editedPipeFamily)) {
      return;
    }
    const editedRow = {
      id: editedPipeFamily.id,
      name: editedPipeFamily.name,
      minLength: getUnitLength(editedPipeFamily.minLength, currentUnitSystem),
      maxLength: getUnitLength(editedPipeFamily.maxLength, currentUnitSystem),
      maxOverallLength: getUnitLength(editedPipeFamily.maxOverallLength, currentUnitSystem),
      minBends: editedPipeFamily.minBends,
      maxBends: editedPipeFamily.maxBends,
      crossSectionShape: editedPipeFamily.crossSectionShape,
      lineType: editedPipeFamily.lineType,
      unitSystemId: editedPipeFamily.unitSystem && editedPipeFamily.unitSystem.id || reduxProps.defaultUnitSystem.id,
      materialId: editedPipeFamily.material.id,
    };

    dispatch(processEditPipeFamily(editedPipeFamily.id, editedRow));
    setIsDialogOpen(false);
  };

  //Cell Bodies
  const textValueBody = (rowdata, field, placeholder = '') => {
    const curRow = editedRows && editedRows.find(item => item.id == rowdata.id) || convertToPipeInstanceType(rowdata);

    return curRow && curRow[`${field}`] ?
      <Text >{curRow[`${field}`]}</Text> :
      <Text style={{ color: colors.gray[5] }} >{placeholder}</Text>;
  };

  const endTypeValueBody = (rowdata, placeholder) => {
    const curRow = editedRows && editedRows.find(item => item.id == rowdata.id) || convertToPipeInstanceType(rowdata);
    const hasEndTypes = curRow && curRow.endTypes && (curRow.endTypes.size > 0 || curRow.endTypes.length > 0);

    return hasEndTypes ?
      <MultiSelectCellRenderer values={curRow.endTypes} /> :
      <Text style={{ color: colors.gray[5] }} >{placeholder}</Text>;
  };

  const unitValueBody = (rowdata, field, placeholder) => {
    const curRow = editedRows && editedRows.find(item => item.id == rowdata.id) || convertToPipeInstanceType(rowdata);

    return (
      <UnitValueCellRenderer data={curRow[field] || ''} unitSystem={reduxProps.defaultUnitSystem} />
    );
  };

  const sBody = (rowdata, field, placeholder) => {
    const curRow = editedRows && editedRows.find(item => item.id == rowdata.id) || convertToPipeInstanceType(rowdata);

    return curRow && curRow[field] ?
      <ComplexCellRenderer value={curRow[field]} isCapitalized={true} paddingLeft='0px' /> :
      <Text className='text-cell-body' style={{ color: colors.gray[5] }}>{placeholder}</Text>;
  };

  // Cell Editors
  const textCellEditor = (field) => <InputText value={currentEditingRow && currentEditingRow[field] || ''} onChange={(e) => onCellChange(e.target.value, field)} />;
  const numberCellEditor = (field) =>
    <span className='p-input-icon-right' >
      <Text as='i' >{currentUnitSystem.unitLength.abbreviation || ''}</Text>
      <InputNumber value={currentEditingRow && getUnitLength(currentEditingRow[field], currentUnitSystem)} onChange={(e) => onCellChange(e.value, field)} minFractionDigits={1} />
    </span>;

  const sizeDropdown = () => {
    return <Dropdown
      value={currentEditingRow && currentEditingRow.sizeProperty}
      options={reduxProps.sizes}
      optionLabel='name'
      filter={true}

      itemTemplate={(item) => ComplexCellRenderer({ value: item, isCapitalized: true })}
      valueTemplate={(item) => ComplexCellRenderer({ value: item, isCapitalized: true })}

      onChange={(e) => onDropdownComplete.current(e.value, 'sizeProperty', currentEditingRow)}
      style={{ width: '100%' }}
    />;
  };

  const scheduleDropdown = () => {
    return <Dropdown
      value={currentEditingRow && currentEditingRow.schedule && currentEditingRow.schedule.id}
      options={reduxProps.schedules}
      optionLabel='value'
      optionValue='id'
      filter={true}

      itemTemplate={(value) => ComplexCellRenderer({ value: value, isCapitalized: true })}
      valueTemplate={() => ComplexCellRenderer({ value: currentEditingRow.schedule, isCapitalized: true })}

      onChange={(e) => onDropdownComplete.current(reduxProps.schedules.find(item => item.id == e.value), 'schedule', currentEditingRow)}
      style={{ width: '100%' }}
    />;
  };

  const endTypeMultiSelect = () => {
    return <MultiSelect
      appendTo={'self'}
      value={getEndTypesArray(currentEditingRow) || []}
      optionLabel='name'
      placeholder='Select End Types'
      display='comma'
      optionDisabled={false}
      options={reduxProps.endTypes}
      onChange={(e) => onCellChange(e.value, 'endTypes')}
      style={{ width: '100%' }}
      useOptionAsValue
    />;
  };

  const getRowTool = (rowdata) => {
    const data = editedRows && editedRows.find(row => row.id == rowdata.id) || reduxProps.data.find((item) => item.id == rowdata.id);
    return (
      <EntityRowTools rowdata={data} handleRowAction={handleRowAction} />
    );
  };

  useEffect(() => {
    dispatch(processFetchIndividualPipeFamily(match.params.id));
  }, []);

  return (
    <>
      {!reduxProps.isLoading && <Flex flexDirection="row" mb={4} >
        <Header
          isLoading={reduxProps.isLoading}
          title={(reduxProps.pipe && reduxProps.pipe.name) || PipingModulePipesEditPath.defaultTitle}
          subtitle={reduxProps.isFiltering ?
            `Showing ${reduxProps.data.length}/${reduxProps.instanceCount} ${pluralize('filtered Pipe Instance', reduxProps.instanceCount)}` :
            `Showing ${reduxProps.instanceCount} ${pluralize('Pipe Instance', reduxProps.instanceCount)} Total`}
        />
        <Options
          updateEntities={() => saveCurrentPipeInstanceChanges(
            editedRows,
            (data) => { dispatch(processSavePipeInstances(reduxProps.pipeId, data)); resetEditedRows(); },
            currentUnitSystem
          )}
          isEditingGrid={isEditing}
          isLoading={reduxProps.isLoading}
          pendingValidChanges={editedRows.length > 0}

          pipeId={(reduxProps.pipe && reduxProps.pipe.id) || null}
          isArchived={reduxProps.pipe && reduxProps.pipe.archivedFlag}
          ConstructedOptions={ConstructedOptions}
          editEntity={() => { setEditedPipeFamily(convertToPipeType(reduxProps.pipe)); setIsDialogOpen(true);}}
          archiveEntity={() => dispatch(processArchivePipeFamilies([reduxProps.pipeId]))}
          unarchiveEntity={() => dispatch(processUnarchivePipeFamilies([reduxProps.pipeId]))}
          manageBends={() => dispatch(push(PipingModulePipesBendsPath.generate({ id: reduxProps.pipeId })))}
          manageBendMachines={() => dispatch(push(PipingModulePipesBendMachinesPath.generate({ id: reduxProps.pipeId })))}
          manageBendSpringbacks={() => dispatch(push(PipingModulePipesBendSpringbacksPath.generate({ id: reduxProps.pipeId })))}
          manageMiters={() => dispatch(push(PipingModulePipesMitersPath.generate({ id: reduxProps.pipeId })))}

          shouldHaveLicense={!reduxProps.hasValidLicense}
          canCollaborate={reduxProps.canCollaborate}
          canFilter={true}
        />
      </Flex> || <Skeleton style={{ height: '2rem', marginBottom: '1rem' }} />
      }
      <Flex style={{ border: '1px solid #DEE2E6', borderRadius: '5px', height: '86%' }} flexDirection='column' >
        <DataTable
          reorderableColumns
          value={reduxProps.data}
          tableStyle={{ minWidth: '55rem' }}
          size='normal'
          editMode='cell'
          rowClassName={(data) => data && rowStyles(data, editedRows)}

          scrollable
          scrollHeight='flex'

          removableSort
        >
          <Column header='' style={{ width: '2%' }} body={(rowdata) => getRowTool(rowdata)} ></Column>
          <Column field='sizeProperty' header='Size' onBeforeCellEditShow={onBeforeCellEditShow} editor={() => sizeDropdown()} body={(rowdata) => sBody(rowdata, 'sizeProperty', 'Select Size')} style={{ width: columnWidth }} ></Column>
          <Column field='schedule' header='Schedule' onBeforeCellEditShow={onBeforeCellEditShow} editor={() => scheduleDropdown()} body={(rowdata) => sBody(rowdata, 'schedule', 'Select Schedule')} style={{ width: columnWidth }} ></Column>
          {reduxProps.pipeShape == PIPE_SHAPES.rectangular.value ?
            [
              <Column key='pipeHeight' field='pipeHeight' style={{ width: columnWidth }} header='Height' onBeforeCellEditShow={onBeforeCellEditShow} editor={() => numberCellEditor('pipeHeight')} onCellEditComplete={(e) => onCellEditComplete.current(e)} body={(rowdata) => unitValueBody(rowdata, 'pipeHeight', '')} ></Column>,
              <Column key='pipeWidth' field='pipeWidth' style={{ width: columnWidth }} header='Width' onBeforeCellEditShow={onBeforeCellEditShow} editor={() => numberCellEditor('pipeWidth')} onCellEditComplete={(e) => onCellEditComplete.current(e)} body={(rowdata) => unitValueBody(rowdata, 'pipeWidth', '')} ></Column>
            ] : <Column field='diameter' style={{ width: columnWidth }} header='Diameter' onBeforeCellEditShow={onBeforeCellEditShow} editor={() => numberCellEditor('diameter')} onCellEditComplete={(e) => onCellEditComplete.current(e)} body={(rowdata) => unitValueBody(rowdata, 'diameter', '')} ></Column>}
          <Column field='thickness' style={{ width: columnWidth }} header='Thickness' onBeforeCellEditShow={onBeforeCellEditShow} editor={() => numberCellEditor('thickness')} onCellEditComplete={(e) => onCellEditComplete.current(e)} body={(rowdata) => unitValueBody(rowdata, 'thickness', '')} ></Column>
          <Column field='endTypes' style={{ width: columnWidth, maxWidth: '250px' }} header='End Types' onBeforeCellEditShow={onBeforeCellEditShow} editor={() => endTypeMultiSelect()} onCellEditComplete={(e) => onCellEditComplete.current(e)} body={(rowdata) => endTypeValueBody(rowdata, 'Select End Types')} ></Column>
          <Column field='stockno' style={{ width: columnWidth }} header='Stock No' onBeforeCellEditShow={onBeforeCellEditShow} editor={() => textCellEditor('stockno')} onCellEditComplete={(e) => onCellEditComplete.current(e)} body={(rowdata) => textValueBody(rowdata, 'stockno', 'Stockno')} ></Column>
          <Column field='mccsCode' style={{ width: columnWidth }} header='MCCS Code' onBeforeCellEditShow={onBeforeCellEditShow} editor={() => textCellEditor('mccsCode')} onCellEditComplete={(e) => onCellEditComplete.current(e)} body={(rowdata) => textValueBody(rowdata, 'mccsCode', 'MCCS Code')} ></Column>
        </DataTable>
        <PipeDialog
          currentEditingRow={editedPipeFamily}
          materials={reduxProps.materials}
          unitSystems={reduxProps.unitSystems}

          editable={reduxProps.editable}
          defaultUnitSystem={reduxProps.defaultUnitSystem}
          getDialogHeader={() => `Edit Pipe Family ${reduxProps.pipe && reduxProps.pipe.name}`}
          handleEditedRowChange={handleEditedDialogChange}
          isDialogOpen={isDialogOpen}
          saveAction={savePipeAction}
          cancelDialogs={() => {setIsDialogOpen(false);}}
          validation={validation}
          getUnitLength={(data) => getUnitLength(data, currentUnitSystem)}
        />
      </Flex>
    </>
  );
}


export default withRouter(PipesEditGrid);